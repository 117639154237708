var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "text-field-suggest-widget",
      staticClass: "text-field-suggest-widget",
      class: { invalid: _vm.invalid },
    },
    [
      _c("input", {
        ref: "keyword-input",
        attrs: {
          type: "text",
          autocomplete: "none",
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.keyword },
        on: { input: _vm.onInput, change: _vm.onChange },
      }),
      _vm.keyword !== ""
        ? _c(
            "button",
            {
              staticClass: "clear",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.clear.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons-round clear" }, [
                _vm._v("close"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showSuggest
        ? _c("div", { staticClass: "suggest-container" }, [
            _c(
              "div",
              { ref: "suggest-list", staticClass: "suggest-list" },
              _vm._l(_vm.filteredSuggests, function (suggest, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "suggest-row",
                    on: {
                      click: function ($event) {
                        return _vm.select(suggest)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(suggest) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
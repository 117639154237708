var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userData
    ? _c(
        "div",
        {
          staticClass: "create-place",
          style: _vm.desktopMode ? "border-radius:10px" : "",
        },
        [
          !_vm.hideTitleBar
            ? _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "close ripple",
                    on: { click: _vm.onClickClose },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "form",
              class: { "hide-title-bar": _vm.hideTitleBar },
            },
            [
              _c(
                "div",
                { staticClass: "longdo-map-container" },
                [
                  _vm.isSatellite
                    ? _c(
                        "button",
                        {
                          staticClass: "ripple base-map-layer normal",
                          attrs: { title: _vm.$t("createPlace.normalMap") },
                          on: { click: _vm.onClickNormalLayer },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("satellite_alt"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isSatellite
                    ? _c(
                        "button",
                        {
                          staticClass: "ripple base-map-layer satellite off",
                          attrs: { title: _vm.$t("createPlace.satelliteMap") },
                          on: { click: _vm.onClickSatelliteLayer },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("satellite_alt"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "ripple current-location",
                      attrs: { title: _vm.$t("createPlace.currentLocation") },
                      on: { click: _vm.onClickSelectCurrentLocation },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isGeolocationMobileAppDisabled
                              ? "location_disabled"
                              : "my_location"
                          )
                        ),
                      ]),
                    ]
                  ),
                  !_vm.isLockLocation
                    ? _c(
                        "button",
                        {
                          staticClass: "ripple off",
                          attrs: { title: _vm.$t("createPlace.lock") },
                          on: { click: _vm.onClickLockMarker },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("lock_open"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isLockLocation
                    ? _c(
                        "button",
                        {
                          staticClass: "ripple",
                          attrs: { title: _vm.$t("createPlace.unlock") },
                          on: { click: _vm.onClickUnlockMarker },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("lock"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isLockLocation
                    ? _c("img", {
                        staticClass: "marker-crosshair",
                        style: _vm.markerCrosshair.style,
                        attrs: { src: _vm.markerCrosshair.src },
                      })
                    : _vm._e(),
                  _c("longdo-map", { on: { load: _vm.loadedLongdoMap } }),
                  _c("div", { staticClass: "location" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.location.lat) +
                        ", " +
                        _vm._s(_vm.location.lon) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.isAdmin && _vm.isEditMode
                ? _c("div", { staticClass: "admin-section" }, [
                    _c("div", { staticClass: "multi-field-in-row" }, [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("createPlace.private")) + " *"
                            ),
                          ]),
                          _c("DropdownWidget", {
                            attrs: {
                              options: _vm.privateList,
                              isRequired: true,
                            },
                            model: {
                              value: _vm.isPrivate,
                              callback: function ($$v) {
                                _vm.isPrivate = $$v
                              },
                              expression: "isPrivate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("createPlace.status")) + " *"),
                          ]),
                          _c("DropdownWidget", {
                            attrs: {
                              options: _vm.statusList,
                              isRequired: true,
                            },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("createPlace.locked")) + " *"),
                          ]),
                          _c("DropdownWidget", {
                            attrs: {
                              options: _vm.lockedList,
                              isRequired: true,
                            },
                            model: {
                              value: _vm.locked,
                              callback: function ($$v) {
                                _vm.locked = $$v
                              },
                              expression: "locked",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "multi-field-in-row" }, [
                _c(
                  "div",
                  { staticClass: "field latitude" },
                  [
                    _c("label", [_vm._v("latitude *")]),
                    _c("TextFieldSuggestWidget", {
                      attrs: {
                        placeholder: "latitude",
                        onChange: _vm.onChangeLatLon,
                        invalid: !_vm.isValidLat,
                      },
                      model: {
                        value: _vm.location.lat,
                        callback: function ($$v) {
                          _vm.$set(_vm.location, "lat", $$v)
                        },
                        expression: "location.lat",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "field longitude" },
                  [
                    _c("label", [_vm._v("longitude *")]),
                    _c("TextFieldSuggestWidget", {
                      attrs: {
                        placeholder: "longitude",
                        onChange: _vm.onChangeLatLon,
                        invalid: !_vm.isValidLon,
                      },
                      model: {
                        value: _vm.location.lon,
                        callback: function ($$v) {
                          _vm.$set(_vm.location, "lon", $$v)
                        },
                        expression: "location.lon",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "field category" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("createPlace.category")) + " *"),
                    ]),
                    _c("DropdownWidget", {
                      attrs: { options: _vm.typeList, isRequired: true },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "multi-field-in-row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowPlaceNameInput,
                        expression: "isShowPlaceNameInput",
                      },
                    ],
                    staticClass: "field place-name-th",
                  },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("createPlace.placeName") +
                            _vm.$t("createPlace.placeNameThPlaceholder")
                        ) + " *"
                      ),
                    ]),
                    _c("TextFieldSuggestWidget", {
                      attrs: { invalid: _vm.isValidPlaceName.th === false },
                      model: {
                        value: _vm.placeName.th,
                        callback: function ($$v) {
                          _vm.$set(_vm.placeName, "th", $$v)
                        },
                        expression: "placeName.th",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowPlaceNameInput,
                        expression: "isShowPlaceNameInput",
                      },
                    ],
                    staticClass: "field place-name-en",
                  },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("createPlace.placeName") +
                            _vm.$t("createPlace.placeNameEnPlaceholder")
                        ) + " *"
                      ),
                    ]),
                    _c("TextFieldSuggestWidget", {
                      attrs: { invalid: _vm.isValidPlaceName.en === false },
                      model: {
                        value: _vm.placeName.en,
                        callback: function ($$v) {
                          _vm.$set(_vm.placeName, "en", $$v)
                        },
                        expression: "placeName.en",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "address-section" }, [
                _c("div", { staticClass: "multi-field-in-row" }, [
                  _c(
                    "div",
                    { staticClass: "field house-no" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.houseNo"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        attrs: { invalid: _vm.isValidHouseNo === false },
                        model: {
                          value: _vm.houseNo,
                          callback: function ($$v) {
                            _vm.houseNo = $$v
                          },
                          expression: "houseNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isThailand,
                          expression: "isThailand",
                        },
                      ],
                      staticClass: "field moo",
                    },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("createPlace.moo")))]),
                      _c("TextFieldSuggestWidget", {
                        attrs: { invalid: _vm.isValidMoo === false },
                        model: {
                          value: _vm.moo,
                          callback: function ($$v) {
                            _vm.moo = $$v
                          },
                          expression: "moo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field village" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.village"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidVillageName.th === false,
                          placeholder: _vm.$t(
                            "createPlace.villageThPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.village.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.village, "th", $$v)
                          },
                          expression: "village.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidVillageName.en === false,
                          placeholder: _vm.$t(
                            "createPlace.villageEnPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.village.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.village, "en", $$v)
                          },
                          expression: "village.en",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "multi-field-in-row" }, [
                  _c(
                    "div",
                    { staticClass: "field condo" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.condo"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidCondoName.th === false,
                          placeholder: _vm.$t("createPlace.condoThPlaceholder"),
                        },
                        model: {
                          value: _vm.condo.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.condo, "th", $$v)
                          },
                          expression: "condo.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidCondoName.en === false,
                          placeholder: _vm.$t("createPlace.condoEnPlaceholder"),
                        },
                        model: {
                          value: _vm.condo.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.condo, "en", $$v)
                          },
                          expression: "condo.en",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field building" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.building"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidBuildingName.th === false,
                          placeholder: _vm.$t(
                            "createPlace.buildingThPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.building.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.building, "th", $$v)
                          },
                          expression: "building.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidBuildingName.en === false,
                          placeholder: _vm.$t(
                            "createPlace.buildingEnPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.building.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.building, "en", $$v)
                          },
                          expression: "building.en",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field floor" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.floor"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        model: {
                          value: _vm.floor,
                          callback: function ($$v) {
                            _vm.floor = $$v
                          },
                          expression: "floor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field room-no" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.roomNo"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        model: {
                          value: _vm.roomNo,
                          callback: function ($$v) {
                            _vm.roomNo = $$v
                          },
                          expression: "roomNo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "multi-field-in-row" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isThailand,
                          expression: "isThailand",
                        },
                      ],
                      staticClass: "field",
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.street"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            "createPlace.streetThPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.street.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.street, "th", $$v)
                          },
                          expression: "street.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            "createPlace.streetEnPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.street.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.street, "en", $$v)
                          },
                          expression: "street.en",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("createPlace.road")))]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("createPlace.roadThPlaceholder"),
                        },
                        model: {
                          value: _vm.road.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.road, "th", $$v)
                          },
                          expression: "road.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("createPlace.roadEnPlaceholder"),
                        },
                        model: {
                          value: _vm.road.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.road, "en", $$v)
                          },
                          expression: "road.en",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "multi-field-in-row" }, [
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.subdistrictFieldLabel) + " "),
                        _vm.isThailand ? _c("span", [_vm._v("*")]) : _vm._e(),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidSubdistrict.th === false,
                          placeholder: _vm.$t(
                            "createPlace.subdistrictThPlaceholder"
                          ),
                          suggests: _vm.subdistrictNameThSuggestList,
                        },
                        on: { select: _vm.onSelectSubdistrictThSuggest },
                        model: {
                          value: _vm.subdistrict.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.subdistrict, "th", $$v)
                          },
                          expression: "subdistrict.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidSubdistrict.en === false,
                          placeholder: _vm.$t(
                            "createPlace.subdistrictEnPlaceholder"
                          ),
                          suggests: _vm.subdistrictNameEnSuggestList,
                        },
                        on: { select: _vm.onSelectSubdistrictEnSuggest },
                        model: {
                          value: _vm.subdistrict.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.subdistrict, "en", $$v)
                          },
                          expression: "subdistrict.en",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.districtFieldLabel) + " "),
                        _vm.isThailand ? _c("span", [_vm._v("*")]) : _vm._e(),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidDistrict.th === false,
                          placeholder: _vm.$t(
                            "createPlace.districtThPlaceholder"
                          ),
                          suggests: _vm.districtNameThSuggestList,
                        },
                        on: { select: _vm.onSelectDistrictThSuggest },
                        model: {
                          value: _vm.district.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.district, "th", $$v)
                          },
                          expression: "district.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidDistrict.en === false,
                          placeholder: _vm.$t(
                            "createPlace.districtEnPlaceholder"
                          ),
                          suggests: _vm.districtNameEnSuggestList,
                        },
                        on: { select: _vm.onSelectDistrictEnSuggest },
                        model: {
                          value: _vm.district.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.district, "en", $$v)
                          },
                          expression: "district.en",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "multi-field-in-row" }, [
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.province")) + " "),
                        _vm.isThailand ? _c("span", [_vm._v("*")]) : _vm._e(),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "th",
                            expression: "langMode === 'th'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidProvince.th === false,
                          placeholder: _vm.$t(
                            "createPlace.provinceThPlaceholder"
                          ),
                          suggests: _vm.provinceNameThSuggestList,
                        },
                        on: { select: _vm.onSelectProvinceThSuggest },
                        model: {
                          value: _vm.province.th,
                          callback: function ($$v) {
                            _vm.$set(_vm.province, "th", $$v)
                          },
                          expression: "province.th",
                        },
                      }),
                      _c("TextFieldSuggestWidget", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.langMode === "en",
                            expression: "langMode === 'en'",
                          },
                        ],
                        attrs: {
                          invalid: _vm.isValidProvince.en === false,
                          placeholder: _vm.$t(
                            "createPlace.provinceEnPlaceholder"
                          ),
                          suggests: _vm.provinceNameEnSuggestList,
                        },
                        on: { select: _vm.onSelectProvinceEnSuggest },
                        model: {
                          value: _vm.province.en,
                          callback: function ($$v) {
                            _vm.$set(_vm.province, "en", $$v)
                          },
                          expression: "province.en",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.postcode")) + " "),
                        _vm.isThailand ? _c("span", [_vm._v("*")]) : _vm._e(),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        attrs: { invalid: _vm.isValidPostcode === false },
                        model: {
                          value: _vm.postcode,
                          callback: function ($$v) {
                            _vm.postcode = $$v
                          },
                          expression: "postcode",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "field" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("createPlace.description"))),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.previewAddressDetail))]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.langMode === "th",
                        expression: "langMode === 'th'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.description.th,
                        expression: "description.th",
                      },
                    ],
                    attrs: {
                      rows: "2",
                      placeholder: _vm.$t(
                        "createPlace.descriptionThPlaceholder"
                      ),
                    },
                    domProps: { value: _vm.description.th },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.description, "th", $event.target.value)
                      },
                    },
                  }),
                  _c("textarea", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.langMode === "en",
                        expression: "langMode === 'en'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.description.en,
                        expression: "description.en",
                      },
                    ],
                    attrs: {
                      rows: "2",
                      placeholder: _vm.$t(
                        "createPlace.descriptionEnPlaceholder"
                      ),
                    },
                    domProps: { value: _vm.description.en },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.description, "en", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("createPlace.country")) + " *"),
                    ]),
                    _c("TextFieldSuggestWidget", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.langMode === "th",
                          expression: "langMode === 'th'",
                        },
                      ],
                      attrs: {
                        invalid: _vm.isValidCountry.th === false,
                        placeholder: _vm.$t("createPlace.countryThPlaceholder"),
                        suggests: _vm.countryNameThSuggestList,
                      },
                      on: { select: _vm.onSelectCountryThSuggest },
                      model: {
                        value: _vm.country.th,
                        callback: function ($$v) {
                          _vm.$set(_vm.country, "th", $$v)
                        },
                        expression: "country.th",
                      },
                    }),
                    _c("TextFieldSuggestWidget", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.langMode === "en",
                          expression: "langMode === 'en'",
                        },
                      ],
                      attrs: {
                        invalid: _vm.isValidCountry.en === false,
                        placeholder: _vm.$t("createPlace.countryEnPlaceholder"),
                        suggests: _vm.countryNameEnSuggestList,
                      },
                      on: { select: _vm.onSelectCountryEnSuggest },
                      model: {
                        value: _vm.country.en,
                        callback: function ($$v) {
                          _vm.$set(_vm.country, "en", $$v)
                        },
                        expression: "country.en",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "field" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ripple",
                      on: { click: _vm.clickChangeLangMode },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("createPlace.changeLangeMode." + _vm.langMode)
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "ripple add-more",
                  attrs: { id: "more-info" },
                  on: {
                    click: function ($event) {
                      _vm.showMore = !_vm.showMore
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(
                      _vm._s(_vm.showMore ? "expand_less" : "expand_more")
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("createPlace.add-more")) + " "),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMore,
                      expression: "showMore",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isAdmin &&
                            _vm.isThailand &&
                            _vm.isShowParentListInput,
                          expression:
                            "isAdmin && isThailand && isShowParentListInput",
                        },
                      ],
                      staticClass: "field",
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.parentPlace"))),
                      ]),
                      _c("DropdownWidget", {
                        attrs: { options: _vm.parentList },
                        model: {
                          value: _vm.parent,
                          callback: function ($$v) {
                            _vm.parent = $$v
                          },
                          expression: "parent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("createPlace.tag")))]),
                      _c("TextFieldSuggestWidget", {
                        attrs: { suggests: _vm.tagSuggestList },
                        on: { select: _vm.onSelectTagSuggest },
                        model: {
                          value: _vm.tags,
                          callback: function ($$v) {
                            _vm.tags = $$v
                          },
                          expression: "tags",
                        },
                      }),
                      _c("span", { staticClass: "guide" }, [
                        _vm._v(_vm._s(_vm.$t("createPlace.guideTag"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("createPlace.contact")) +
                            " (" +
                            _vm._s(_vm.telList.length) +
                            ")"
                        ),
                      ]),
                      _vm.isRepeatedTel
                        ? _c("span", { staticClass: "warning" }, [
                            _c("i", { staticClass: "material-icons-round" }, [
                              _vm._v("warning"),
                            ]),
                            _vm._v(_vm._s(_vm.$t("createPlace.repeatedTel"))),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.telList, function (tel, index) {
                        return _c("TelInput", {
                          key: "tel-" + tel.id,
                          attrs: { userData: _vm.userData },
                          on: {
                            delete: function ($event) {
                              return _vm.deleteContact(index)
                            },
                          },
                          model: {
                            value: _vm.telList[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.telList, index, $$v)
                            },
                            expression: "telList[index]",
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "ripple add-contact",
                      on: { click: _vm.addTel },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("createPlace.addContact")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.website"))),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        model: {
                          value: _vm.websiteUrl,
                          callback: function ($$v) {
                            _vm.websiteUrl = $$v
                          },
                          expression: "websiteUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.hideFullDescription
                    ? _c(
                        "div",
                        { staticClass: "field full-description" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("createPlace.fullDescriptionTh"))
                            ),
                          ]),
                          _c("VueEditor", {
                            attrs: { editorToolbar: _vm.customToolbar },
                            model: {
                              value: _vm.fullDescription.th,
                              callback: function ($$v) {
                                _vm.$set(_vm.fullDescription, "th", $$v)
                              },
                              expression: "fullDescription.th",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideFullDescription
                    ? _c(
                        "div",
                        { staticClass: "field full-description" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("createPlace.fullDescriptionEn"))
                            ),
                          ]),
                          _c("VueEditor", {
                            attrs: { editorToolbar: _vm.customToolbar },
                            model: {
                              value: _vm.fullDescription.en,
                              callback: function ($$v) {
                                _vm.$set(_vm.fullDescription, "en", $$v)
                              },
                              expression: "fullDescription.en",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("createPlace.workingHours"))),
                      ]),
                      _vm._l(_vm.dayList, function (day, index) {
                        return _c(
                          "div",
                          { key: day.name, staticClass: "day" },
                          [
                            _c("label", { staticClass: "name" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: day.selected,
                                    expression: "day.selected",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(day.selected)
                                    ? _vm._i(day.selected, null) > -1
                                    : day.selected,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChangeDayCheckbox(index)
                                  },
                                  change: function ($event) {
                                    var $$a = day.selected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            day,
                                            "selected",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            day,
                                            "selected",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(day, "selected", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("day." + day.name)) + " "
                              ),
                            ]),
                            day.selected
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "time start" },
                                    [
                                      _c("label", { staticClass: "topic" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "createPlace.workingHoursOpen"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("DropdownWidget", {
                                        staticClass: "hour",
                                        attrs: {
                                          isRequired: true,
                                          options: _vm.hourList,
                                          disabled:
                                            !day.selected || day.selectedAllDay,
                                        },
                                        model: {
                                          value: day.startHour,
                                          callback: function ($$v) {
                                            _vm.$set(day, "startHour", $$v)
                                          },
                                          expression: "day.startHour",
                                        },
                                      }),
                                      _c("label", [_vm._v(":")]),
                                      _c("DropdownWidget", {
                                        staticClass: "min",
                                        attrs: {
                                          isRequired: true,
                                          options: _vm.minList,
                                          disabled:
                                            !day.selected || day.selectedAllDay,
                                        },
                                        model: {
                                          value: day.startMin,
                                          callback: function ($$v) {
                                            _vm.$set(day, "startMin", $$v)
                                          },
                                          expression: "day.startMin",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "time end" },
                                    [
                                      _c("label", { staticClass: "topic" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "createPlace.workingHoursClose"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("DropdownWidget", {
                                        staticClass: "hour",
                                        attrs: {
                                          isRequired: true,
                                          options: _vm.hourList,
                                          disabled:
                                            !day.selected || day.selectedAllDay,
                                        },
                                        model: {
                                          value: day.endHour,
                                          callback: function ($$v) {
                                            _vm.$set(day, "endHour", $$v)
                                          },
                                          expression: "day.endHour",
                                        },
                                      }),
                                      _c("label", [_vm._v(":")]),
                                      _c("DropdownWidget", {
                                        staticClass: "min",
                                        attrs: {
                                          isRequired: true,
                                          options: _vm.minList,
                                          disabled:
                                            !day.selected || day.selectedAllDay,
                                        },
                                        model: {
                                          value: day.endMin,
                                          callback: function ($$v) {
                                            _vm.$set(day, "endMin", $$v)
                                          },
                                          expression: "day.endMin",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "time" }, [
                                    _c("label", { staticClass: "all-day" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: day.selectedAllDay,
                                            expression: "day.selectedAllDay",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          disabled: !day.selected,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            day.selectedAllDay
                                          )
                                            ? _vm._i(day.selectedAllDay, null) >
                                              -1
                                            : day.selectedAllDay,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onChangeAllDayCheckbox(
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            var $$a = day.selectedAllDay,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    day,
                                                    "selectedAllDay",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    day,
                                                    "selectedAllDay",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                day,
                                                "selectedAllDay",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("createPlace.allDay")) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      _c("label", { staticClass: "topic" }, [
                        _vm._v(
                          _vm._s(_vm.$t("createPlace.workingHoursRemark"))
                        ),
                      ]),
                      _c("TextFieldSuggestWidget", {
                        model: {
                          value: _vm.workingHoursRemark,
                          callback: function ($$v) {
                            _vm.workingHoursRemark = $$v
                          },
                          expression: "workingHoursRemark",
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEditMode || true,
                          expression: "!isEditMode || true",
                        },
                      ],
                      staticClass: "field",
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("createPlace.image")) +
                            " (" +
                            _vm._s(_vm.imageList.length) +
                            ")"
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          ref: "preview-image-list",
                          staticClass: "preview-image-list",
                        },
                        [
                          _vm._l(_vm.imageList, function (image, index) {
                            return _c("ImageWidget", {
                              key: "image-" + index,
                              style: {
                                marginRight:
                                  (index + 1) % _vm.previewImagePerRow === 0
                                    ? "0"
                                    : _vm.previewImageGap + "px",
                                marginBottom: _vm.previewImageGap + "px",
                              },
                              attrs: {
                                image: image,
                                width: _vm.previewImageSize,
                                height: _vm.previewImageSize,
                                canEdit: image.file !== null,
                                canDelete: image.file !== null,
                              },
                              on: {
                                delete: function ($event) {
                                  return _vm.onDeleteImage(index)
                                },
                              },
                            })
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "add-image ripple",
                              style: {
                                width: _vm.previewImageSize,
                                height: _vm.previewImageSize,
                              },
                              on: { click: _vm.onClickAddImage },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("add_photo_alternate"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("createPlace.addImage")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "add-image-input",
                            staticClass: "add-image-input",
                            attrs: {
                              type: "file",
                              accept: "image/*",
                              multiple: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.manageImageList()
                              },
                            },
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "action" }, [
            _c(
              "button",
              {
                staticClass: "ripple",
                attrs: { disabled: !_vm.isValid || _vm.isSavingPlace },
                on: {
                  click: function ($event) {
                    return _vm.beforeSave()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.saveButtonLabel))]
            ),
          ]),
          _vm.isShowAlertBeforeSave
            ? _c(
                "div",
                {
                  staticClass: "alert-before-save",
                  style: _vm.desktopMode ? "border-radius:10px" : "",
                },
                [
                  _c("div", { staticClass: "dialog-card" }, [
                    _c("div", { staticClass: "msg" }, [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("warning"),
                      ]),
                      _c("br"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("createPlace.alertBeforeSave.msg")
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "action" }, [
                      _c(
                        "button",
                        {
                          staticClass: "ripple outline",
                          on: {
                            click: function ($event) {
                              _vm.isShowAlertBeforeSave = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("createPlace.alertBeforeSave.edit")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ripple",
                          attrs: { disabled: _vm.countDownConfirm > 0 },
                          on: { click: _vm.savePlace },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("createPlace.alertBeforeSave.confirm")
                              ) +
                              " " +
                              _vm._s(
                                _vm.countDownConfirm > 0
                                  ? "(" + _vm.countDownConfirm + ")"
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.isSavingPlace
            ? _c("div", { staticClass: "loading-overlay" }, [
                _vm.createErrorMessage === "" && _vm.createSuccessMessage === ""
                  ? _c("img", {
                      attrs: { src: require("@/assets/img/loading.gif") },
                    })
                  : _vm._e(),
                _vm.createErrorMessage !== ""
                  ? _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.createErrorMessage)),
                    ])
                  : _vm._e(),
                _vm.createSuccessMessage !== ""
                  ? _c("span", { staticClass: "success-message" }, [
                      _vm._v(_vm._s(_vm.createSuccessMessage)),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tel-input" },
    [
      _c("div", { staticClass: "preview" }, [
        _vm.type.value === "L"
          ? _c("div", {
              staticClass: "type",
              style: {
                backgroundImage:
                  "url('" +
                  _vm.baseUrl +
                  "img/line_logo.svg?version=" +
                  _vm.appVersion +
                  "')",
              },
            })
          : _c("i", { staticClass: "material-icons-round type" }, [
              _vm._v(_vm._s(_vm.type.icon)),
            ]),
        _c("div", { staticClass: "summary" }, [
          _c("div", [_vm._v(_vm._s(_vm.summary))]),
          _c("div", { staticClass: "remark" }, [_vm._v(_vm._s(_vm.remark))]),
        ]),
        _vm.showRequiredVerification
          ? _c(
              "button",
              {
                staticClass: "required-verification",
                on: { click: _vm.clickRequiredVerification },
              },
              [
                _c("img", {
                  attrs: {
                    alt: "verify",
                    src: _vm.baseUrl + "img/required_verification.svg",
                  },
                }),
                _c("div", { staticClass: "guide" }, [
                  _vm._v("กดยืนยันหมายเลข"),
                ]),
              ]
            )
          : _vm._e(),
        _vm.showVerified
          ? _c("button", { staticClass: "ripple confirm-verification" }, [
              _c("img", {
                attrs: { src: _vm.baseUrl + "img/confirm_verification.svg" },
              }),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "ripple delete",
            on: {
              click: function ($event) {
                return _vm.$emit("delete")
              },
            },
          },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("delete")])]
        ),
        _c(
          "button",
          {
            staticClass: "ripple edit",
            class: { active: _vm.isEditMode },
            on: {
              click: function ($event) {
                _vm.isEditMode = !_vm.isEditMode
              },
            },
          },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("edit")])]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEditMode,
              expression: "isEditMode",
            },
          ],
          staticClass: "form",
        },
        [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("telInput.type")) + " *")]),
              _c("DropdownWidget", {
                attrs: {
                  options: _vm.telType,
                  isRequired: true,
                  hasFilter: false,
                },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "multi-field-in-row" }, [
            _vm.type.value !== "S" && _vm.type.value !== "L"
              ? _c(
                  "div",
                  {
                    staticClass: "field",
                    staticStyle: { width: "calc((100% - 12px) / 3)" },
                  },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("telInput.countryCode"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "align-items": "center",
                          width: "100%",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "0px 6px 0px 0px",
                              "font-size": "16px",
                              "padding-top": "6px",
                            },
                          },
                          [_vm._v("+")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.countryCode,
                              expression: "countryCode",
                            },
                          ],
                          staticClass: "no-spin-button",
                          staticStyle: { width: "100%" },
                          attrs: { type: "number", step: "any" },
                          domProps: { value: _vm.countryCode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.countryCode = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.type.value !== "L"
              ? _c(
                  "div",
                  {
                    staticClass: "field",
                    style:
                      _vm.type.value === "S"
                        ? "width: 100%;"
                        : "width: calc((100% - 12px) * 2 / 3);",
                  },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("telInput.telNo")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.telNo,
                          expression: "telNo",
                        },
                      ],
                      staticClass: "no-spin-button",
                      attrs: { type: "number", maxlength: "20", step: "any" },
                      domProps: { value: _vm.telNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.telNo = $event.target.value
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.type.value !== "L"
            ? _c("div", { staticClass: "multi-field-in-row" }, [
                _vm.type.value !== "M"
                  ? _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { width: "calc((100% - 12px) / 3)" },
                      },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("telInput.telNoTo"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.telNoTo,
                              expression: "telNoTo",
                            },
                          ],
                          staticClass: "no-spin-button",
                          attrs: { type: "number", step: "any" },
                          domProps: { value: _vm.telNoTo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.telNoTo = $event.target.value
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.type.value !== "M"
                  ? _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: {
                          width: "calc((100% - 12px) / 3)",
                          margin: "0px auto 0px 12px",
                        },
                      },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("telInput.ext")))]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ext,
                              expression: "ext",
                            },
                          ],
                          staticClass: "no-spin-button",
                          attrs: { type: "text", step: "any" },
                          domProps: { value: _vm.ext },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.ext = $event.target.value
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.type.value === "L"
            ? _c(
                "div",
                { staticClass: "field" },
                [
                  _c("label", [_vm._v("LINE ID")]),
                  _c("TextFieldSuggestWidget", {
                    model: {
                      value: _vm.lineId,
                      callback: function ($$v) {
                        _vm.lineId = $$v
                      },
                      expression: "lineId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.type.value === "L"
            ? _c("div", { staticClass: "field" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("telInput.orLink")))]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "align-items": "center",
                      width: "100%",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "0px 6px 0px 0px",
                          "font-size": "16px",
                          "margin-top": "6px",
                        },
                      },
                      [_vm._v("https://line.me/ti/p/")]
                    ),
                    _c("TextFieldSuggestWidget", {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.lineLink,
                        callback: function ($$v) {
                          _vm.lineLink = $$v
                        },
                        expression: "lineLink",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "field" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("telInput.remark")))]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.remark,
                  expression: "remark",
                },
              ],
              attrs: { rows: "2" },
              domProps: { value: _vm.remark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.remark = $event.target.value
                },
              },
            }),
            _c("span", { staticClass: "guide" }, [
              _vm._v(_vm._s(_vm.$t("telInput.guideRemark"))),
            ]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c(
              "button",
              {
                staticClass: "ripple",
                staticStyle: { "margin-left": "auto" },
                on: {
                  click: function ($event) {
                    _vm.isEditMode = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("telInput.collapse")))]
            ),
          ]),
        ]
      ),
      _c("VerificationDialog", {
        attrs: { "tel-number": _vm.mobileNumber, userData: _vm.userData },
        on: {
          close: _vm.onCloseVerificationDialog,
          success: _vm.onSuccessVerification,
        },
        model: {
          value: _vm.showVerificationDialog,
          callback: function ($$v) {
            _vm.showVerificationDialog = $$v
          },
          expression: "showVerificationDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
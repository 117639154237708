var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "dropdown-widget", staticClass: "dropdown-widget" }, [
    _c(
      "div",
      {
        staticClass: "selected",
        class: { disabled: _vm.disabled },
        on: { click: _vm.clickInput },
      },
      [
        _vm.selectedIndex !== null &&
        (_vm.options[_vm.selectedIndex].image || "") !== ""
          ? _c("div", { staticClass: "image-container" }, [
              _c("img", {
                attrs: { src: _vm.options[_vm.selectedIndex].image },
              }),
            ])
          : _vm._e(),
        _vm.selectedIndex !== null
          ? _c("div", { staticClass: "name" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$i18n.locale === "th"
                      ? _vm.options[_vm.selectedIndex].label
                      : _vm.options[_vm.selectedIndex].labelEn
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.selectedIndex === undefined || _vm.selectedIndex === null
          ? _c("div", { staticClass: "placeholder" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.placeholder || "" + _vm.$t("dropdownWidget.select")
                  ) +
                  " " +
                  _vm._s(_vm.isRequired ? "*" : "") +
                  " "
              ),
            ])
          : _vm._e(),
        _c(
          "i",
          {
            staticClass: "material-icons-round arrow-dropdown",
            class: { active: _vm.options.length > 0 && _vm.showDropdown },
          },
          [_vm._v("arrow_drop_down")]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.options.length > 0 && _vm.showDropdown,
            expression: "options.length > 0 && showDropdown",
          },
        ],
        ref: "dropdown",
        staticClass: "dropdown",
      },
      [
        _vm.hasFilter
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.keyword,
                  expression: "keyword",
                },
              ],
              ref: "keyword",
              attrs: { type: "search", autocomplete: "none" },
              domProps: { value: _vm.keyword },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    ) {
                      return null
                    }
                    return _vm.down.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    ) {
                      return null
                    }
                    return _vm.up.apply(null, arguments)
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.keyword = $event.target.value
                },
              },
            })
          : _vm._e(),
        _c(
          "div",
          { ref: "option-list", staticClass: "option-list" },
          [
            !_vm.isRequired
              ? _c(
                  "div",
                  {
                    staticClass: "option-row",
                    class: { "selected-row": "" === _vm.selectedValue },
                    on: {
                      click: function ($event) {
                        return _vm.select("")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dropdownWidget.unselect")) + " "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._l(_vm.filteredOptions, function (option) {
              return _c(
                "div",
                {
                  key: option.value,
                  staticClass: "option-row",
                  class: { "selected-row": option.value === _vm.selectedValue },
                  on: {
                    click: function ($event) {
                      return _vm.select(option.value)
                    },
                  },
                },
                [
                  (option.image || "") !== ""
                    ? _c("div", { staticClass: "image-container" }, [
                        _c("img", {
                          attrs: { src: option.image, loading: "lazy" },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.locale === "th"
                            ? option.label
                            : option.labelEn
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
            _vm.filteredOptions.length == 0
              ? _c("div", { staticClass: "option-row no-result" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchResultPanel.empty")) + " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userData
    ? _c("div", { staticClass: "report-place" }, [
        !_vm.hideTitleBar
          ? _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "button",
                {
                  staticClass: "close ripple",
                  on: { click: _vm.onClickClose },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("close"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "form",
            class: { "hide-title-bar": _vm.hideTitleBar },
          },
          [
            _c("div", { staticClass: "place-name" }, [
              _vm._v(_vm._s(_vm.placeName)),
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("reportPlace.reason")))]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reason,
                    expression: "reason",
                  },
                ],
                attrs: { rows: "5" },
                domProps: { value: _vm.reason },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.reason = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "action" }, [
          _c(
            "button",
            {
              staticClass: "ripple",
              attrs: { disabled: !_vm.isValid || _vm.isSubmitingReportPlace },
              on: { click: _vm.submitReportPlace },
            },
            [_vm._v(_vm._s(_vm.submitButtonLabel))]
          ),
        ]),
        _vm.isSubmitingReportPlace
          ? _c("div", { staticClass: "loading-overlay" }, [
              _vm.reportErrorMessage === "" && _vm.reportSuccessMessage === ""
                ? _c("img", {
                    attrs: { src: require("@/assets/img/loading.gif") },
                  })
                : _vm._e(),
              _vm.reportErrorMessage !== ""
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.reportErrorMessage)),
                  ])
                : _vm._e(),
              _vm.reportSuccessMessage !== ""
                ? _c("span", { staticClass: "success-message" }, [
                    _vm._v(_vm._s(_vm.reportSuccessMessage)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
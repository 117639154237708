var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c("div", { staticClass: "back-drop" }, [
        _c("div", { staticClass: "dialog-card" }, [
          _c(
            "button",
            { staticClass: "close ripple", on: { click: _vm.onClickClose } },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v("close"),
              ]),
            ]
          ),
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("verificationDialog.enterVerificationCode")) +
                " "
            ),
          ]),
          !_vm.isExceedsLimitSmsPerDay && !_vm.isExceedsLimitVerifyPerHour
            ? _c(
                "div",
                {
                  staticClass: "otp-input-group",
                  on: { click: _vm.onClickOtpInputGroup },
                },
                [
                  _vm._l(_vm.otpLength, function (n) {
                    return _c(
                      "div",
                      {
                        key: "otp-digit-" + n,
                        staticClass: "digit-preview ripple",
                        class: {
                          active: n - 1 < _vm.otp.length,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.otp[n - 1] || "") + " ")]
                    )
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.otp,
                        expression: "otp",
                      },
                    ],
                    ref: "otp-input",
                    staticClass: "no-spin-button",
                    attrs: {
                      type: "number",
                      step: "any",
                      pattern: "\\d*",
                      autocomplete: "off",
                      autocorrect: "off",
                      autocapitalize: "off",
                      maxlength: _vm.otpLength,
                    },
                    domProps: { value: _vm.otp },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.otp = $event.target.value
                      },
                    },
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "guide" }, [
            _vm._v(" " + _vm._s(_vm.$t("verificationDialog.guide")) + " "),
            _c("span", { staticClass: "tel-number" }, [
              _vm._v(_vm._s(_vm.telNumber)),
            ]),
          ]),
          _c("div", { staticClass: "guide" }, [
            _vm._v(" " + _vm._s(_vm.$t("verificationDialog.guide1")) + " "),
          ]),
          _vm.isExceedsLimitSmsPerDay
            ? _c("div", { staticClass: "guide error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("verificationDialog.guideExceedsLimitSmsPerDay")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isFailedSendingSms
            ? _c("div", { staticClass: "guide error" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("verificationDialog.guideFailedSendingSms")) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isExceedsLimitVerifyPerHour
            ? _c("div", { staticClass: "guide error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "verificationDialog.guideExceedsLimitVerifyPerHour"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isIncorrectVerificationCode
            ? _c("div", { staticClass: "guide error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "verificationDialog.guideIncorrectVerificationCode"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "action" }, [
            _c(
              "button",
              {
                staticClass: "ripple outline",
                attrs: { disabled: !_vm.canResend },
                on: { click: _vm.onClickResend },
              },
              [_vm._v(" " + _vm._s(_vm.resendButtonLabel) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "ripple",
                attrs: { disabled: !_vm.canSubmit },
                on: { click: _vm.onClickSubmit },
              },
              [_vm._v(" " + _vm._s(_vm.$t("verificationDialog.submit")) + " ")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-place" },
    [
      !_vm.isLoading &&
      (!_vm.isEditMode || (_vm.isEditMode && _vm.canEdit)) &&
      !_vm.isReport
        ? _c("CreatePlace", {
            attrs: {
              detail: _vm.detail,
              userData: _vm.user,
              hideTitleBar: _vm.hideTitleBar,
              initialLocation: !_vm.isEditMode ? _vm.location : null,
              initialZoom: _vm.zoom,
              hideFullDescription:
                _vm.platform === "android" || _vm.platform === "ios",
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
            },
            on: { success: _vm.onSuccess },
          })
        : _vm._e(),
      !_vm.isLoading && _vm.isReport
        ? _c("ReportPlace", {
            attrs: { detail: _vm.detail, userData: _vm.user },
            on: { success: _vm.onSuccess },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }